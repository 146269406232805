@forward "./main-scss/";
@forward "./partials/betting-site";
@forward "./listing/listing-common";
@use "./config/" as *;

.betting-listing {
    @extend %py-5;
    .waf-head {
        @extend %px-4;
        @extend %mb-5;
        @include drop-arrow;
        --_border-color: var(--secondary-800);
        --_more-color: var(--secondary-800);
    }
    .article {
        &-list {
            overflow-x: auto;
            gap: var(--space-4);
            @extend %px-4;
            @extend %flex;
        }
        &-item {
            flex-shrink: 0;
            width: 75%;
        }
        &-content {
            padding: var(--space-4);
            background: var(--pure-white-900);
        }
        &-title {
            @extend %neutral-800;
        }
        &-meta {
            align-items: center;
            margin-top: var(--space-3);
            .meta-category {
                left: var(--space-4);
                top: var(--space-2);
            }
        }
    }
}
@include mq(col-md) {
    .betting-listing {
        .waf-head {
            padding-inline: 0;
        }
        .article {
            &-list {
                flex-wrap: nowrap;
                padding-inline: 0;
            }
            &-item {
                width: 35%;
            }
        }
    }  
}
@include mq(col-lg) {
    .waf-listing {
        &.betting-listing {
            .article-item {
                width: calc(25% - var(--space-3)); 
            }
        }
    }
}